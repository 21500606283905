.animated-card {
    opacity: 0;
    transform: translateY(300px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
.animated-card.animate-up {
    opacity: 1;
    transform: translateY(0);
}

.fade-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  
.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
opacity: 1;
transform: translateX(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
}

.unfade-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  
.unfade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.unfade-exit {
opacity: 1;
transform: translateX(0);
}

.unfade-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}


.midfade-enter {
    opacity: 0;
    transform: translateX(-100%);
}
  
.midfade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 3000ms, transform 300ms;
}

.midfade-exit {
    opacity: 1;
    transform: translateX(0);
    }
    
.midfade-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 3000ms, transform 300ms;
}

.unmidfade-enter {
    opacity: 0;
    transform: translateX(100%);
}
  
.unmidfade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 3000ms, transform 300ms;
}

.unmidfade-exit {
    opacity: 1;
    transform: translateX(0);
}

.unmidfade-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
}
  
  